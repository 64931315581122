.Slider {
	--padding: 16px;
	.valueRange {
		.rail {
			.value {
			}
		}
		.center {
		}
	}

	input[type='range'] {
		width: 100%;
		height: 100%;
		position: absolute;
		margin: 0;
		appearance: none;
		-webkit-appearance: none;
		background: transparent;
		&::ms-thumb {
			appearance: none;
			background: #ffffff;
			cursor: pointer;
			height: var(--padding);
			width: var(--padding);
			border-radius: var(--padding);
			border: black solid 1px;
		}
		&::-moz-range-thumb {
			appearance: none;
			background: #ffffff;
			cursor: pointer;
			height: var(--padding);
			width: var(--padding);
			border-radius: var(--padding);
			border: black solid 1px;
		}

		&::-webkit-slider-thumb {
			appearance: none;
			background: #ffffff;
			cursor: pointer;
			height: var(--padding);
			width: var(--padding);
			border-radius: var(--padding);
			border: black solid 1px;
		}
		&:disabled {
			&::-webkit-slider-thumb {
				background: #8f8f8f;
			}
		}
	}
}
