.Main {
  text-align: center;
}

#title {
  font-size: 120px;
  margin-bottom: 0px;
}

#semi-title {
  margin-top: 0px;
  margin-left: 300px;
}