.DroneController {
	height: 100vh;
	width: 100vw;
	max-height: 100%;
	float: left;
	width: 100%;
	overflow: hidden;

	.online {
		color: rgb(17, 171, 17);
	}
	.offline {
		color: rgb(201, 34, 34);
	}
}


.center-items {
	display: flex;
	justify-content: center;
	align-items: center;
}